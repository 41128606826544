<template> 
    <div class="page-padding">
        <div v-if="loadingProjects || hasProjects">
            <div class="flex space-between mb-l">
                <h2>Projecten</h2>
                <div class="flex" style="gap:10px;">
                    <jump-transition>
                            <q-button   
                            class="export-button" variation="light"
                            icon="download"
                            v-if="!freemiumOrganisation && projectsMinimal.length"
                            @click="downloadExport"
                            >{{ exportButtonText }}
                        </q-button>
                    </jump-transition>                    
                    <q-button
                        v-if="canCreateNewProject"
                        v-can="'Project__create_crow'"
                        :iconStyle="iconStyle"
                        iconSize="22"
                        icon="helmet"
                        @click="$router.push('/projects/create')"
                        >Nieuw project
                    </q-button>                                                         
                </div>                
            </div>
            <q-table-with-pagination
                :data="formattedProjects"
                :columns="columns"
                :tableLoading="loadingProjects"
                :maxRows="paginatedTableLength"
                :dataLength="projectsMinimal.length"
                :isSearching="Boolean(selectedFiltersLength)"
                clickable
                ref="projectsTable"
                @click="select"
                @filterUpdated="handleFilterUpdated"
                @filterSearch="_filterSearchChanged"
                @pageSelection="handleTablePagination"
                @filterInitialized="handleFiltersInitialized"
                @tableInitialized="handleUpdateTable"
            >
                <template v-slot:row="{ row, column }">
                    <span v-if="column == 'norm'">
                        <img
                            v-if="row.usesCrowFlow"
                            class="crow-logo"
                            src="https://storage.googleapis.com/qfact_uploads_production/misc/crow-logo.png"
                            alt="crow-logo"
                        />
                    </span>
                    <span v-else-if="column == 'statusLabel'" style="display:flex;justify-content:flex-end">
                        <q-tag
                            size="medium"
                            :variation="row.status !== 'finished' ? 'warning' : 'success'"
                            >{{ row[column] }}</q-tag
                        >
                    </span>
                    <span v-else-if="column == 'date'">
                        {{ row.dateFormatted }}
                    </span>
                    <span v-else-if="column == 'members'">
                        <q-avatar-group :images="getMembersAvatars(row[column])" :max="2" />
                    </span>
                    <span v-else-if="column == 'name'" class="boldText" style="white-space:nowrap">
                        {{ row[column] }}
                    </span>
                    <span v-else style="white-space:nowrap">
                        {{ row[column] }}
                    </span>
                </template>
            </q-table-with-pagination>
        </div>

        <div v-else>
            <q-zerostate
                class="zerostate"
                img="/statics/img/project_gradient.svg"
                title="Projecten"
                :description="zerostateDescription"
            >
                <q-button v-if="canCreateNewProject" v-can="'Project__create_crow'" @click="createProject">Nieuw project maken</q-button>
            </q-zerostate>
        </div>

        <q-popup v-if="showContractorCrowProductPopup" @close="showContractorCrowProductPopup = false" showCloseButton>
            <div class="modal">
                <h2 class="pb-s">
                    CROW product<br />
                    niet geactiveerd
                </h2>
                <p>
                    Uw organisatie heeft niet het CROW product geactiveerd.<br />
                    Neem contact op met de helpdesk
                </p>
                <div class="footer flex-end"></div>
            </div>
        </q-popup>        
    </div>
</template>

<script>
import _ from 'lodash';

import { GET_PROJECTS, GET_PROJECTS_MINIMAL, ORGANISATIONS_MINIMAL, ORGANISATION_USERS } from '../../graphql/queries';
import { getDate, extractError, canCreateNewProject, userInitials, getCalculatedRows, handleExportCsv, freemiumOrganisation } from '../../assets/js/utils';

export default {
    name: 'Projects',
    data() {
        return {
            loadingProjects: true,
            loadingProjectFilterOptions: true,
            loadingOrganisationFilterOptions: true,
            loadingMemberFilterOptions: true,
            loadingStatusFilterOptions: true,
            initialisedProjects: false,
            zerostateDescription: this.ability.get().can('create_crow', 'Project')
                ? 'Je hebt nog geen project aangemaakt, of je bent nog niet uitgenodigd om aan een project deel te nemen. Ga vandaag aan de slag met je eerste project!'
                : 'Er zijn nog geen project waar u lid van bent. Vraag een projectbeheerder om uitgenodigd te worden voor een project.',
            iconStyle: {
                marginLeft: '-10px',
                marginTop: '-2px'
            },
            projects: [],
            projectsFilterOptions: [],
            organisationFilterOptions: [],
            whereQuery: {},
            sorting: ['creationDate__DESC'],
            hasProjects: false,
            showContractorCrowProductPopup: false,
            paginatedTableLength: 10,
            paginatedProjects: [],
            projectsMinimal: [],
            allMinimalProjects: [],
            membersFilterOptions: [],
            statusFilterOptions: [],
            selectedFiltersLength: 0,
            projectsOrganisationIds: [],
            initializedTableLength: false,
            activeTableIndex: 0,
            exportingProjects: false,
            exportedProjects: []
        };
    },
    methods: {
        select(row) {
            if (row.status === 'finished' && !this.canViewFinishedProjects) this.showContractorCrowProductPopup = true;
        },
        createProject() {
            this.$router.push('/projects/create');
        },
        get() {
            const first = this.paginatedTableLength;
            const skip = this.paginatedTableLength * this.activeTableIndex;
            const fetchPolicy = this.getFetchPolicy();

            this.loadingProjects = true;

            this.$apollo
                .query({
                    query: GET_PROJECTS,
                    variables: {
                        where: this.whereQuery,
                        sort: this.sorting,
                        first: first,
                        skip: skip
                    },
                    fetchPolicy
                })
                .then(response => {  
                    this.paginatedProjects = this.transformColumns(response.data.projects);
                    if(!this.hasProjects) this.hasProjects = this.paginatedProjects.length > 0;
                    this.loadingProjects = false;
                })
                .catch(error => {
                    (this.loadingProjects = false), this.$store.commit('notify', extractError(error));
                });
        },
        getDate(timestamp) {
            return getDate(timestamp);
        },
        setFilters(filter) {
            const organisationType = this.$store.getters.getOrganisationType;
            if(Object.keys(filter).length) {
                const filters = Object.keys(filter).filter(filterName => {
                    if(typeof filter[filterName].filter === 'array' && filter[filterName].filter.length > 0) return true
                    if(typeof filter[filterName].filter === 'object' && Object.keys(filter[filterName].filter).length > 0) return true
                });
                this.selectedFiltersLength = filters.length;
                try {
                    this.$refs.projectsTable.resetCounter();
                } catch(error) {}
            }

            if(organisationType === 'client') filter.private__in = [false, null];

            let query = {};
            const { id: userId } = this.$store.getters.getUser;

            if (this.$route.name === 'ProjectsMine')
                query = {
                    members__some: {
                        userId
                    }
                };

            const statusKey = `${organisationType}Status__in`;

            this.whereQuery = {
                AND: [
                    ...Object.keys(filter)
                        .filter(field => {
                            switch (field) {
                                case 'name':
                                case 'contractor':
                                case 'client':
                                case 'members':
                                    return filter[field].filter.length > 0;
                                case 'statusLabel':
                                    return filter[field].filter.length > 0;
                                case 'private__in':
                                    return true;
                                default:
                                    return true;
                            }
                        })
                        .map(field => {
                            switch (field) {
                                case 'name':
                                    return {
                                        id__in: filter[field].filter
                                    };
                                case 'date':
                                    return {
                                        creationDate__gte: filter[field].filter.from,
                                        creationDate__lte: filter[field].filter.to
                                    };
                                case 'contractor':
                                    return {
                                        contractorId__in: filter[field].filter
                                    };
                                case 'client':
                                    return {
                                        clientId__in: filter[field].filter
                                    };
                                case 'members':
                                    return {
                                        members_userId__in: filter[field].filter
                                    }
                                case 'statusLabel':
                                    return {
                                        [statusKey]: filter[field].filter
                                    }
                                case 'private__in':
                                    return { private__in: filter[field] }
                                default:
                                    return {};
                            }
                        }),
                    query
                ]
            };

            let sorting = [];

            this.sorting = Object.keys(filter)
                .filter(field => filter[field].latest)
                .map(field => {
                    const sort = this.getSortingCondition(field, filter[field].sorting);
                    sorting.push(sort);
                });

            if(Object.keys(filter).length === 0) {
                const defaultSortColumn = this.columns.find(column => column.defaultSort);
                if(defaultSortColumn) {
                    const sort = this.getSortingCondition(defaultSortColumn.field, defaultSortColumn.defaultSort);
                    sorting.push(sort);
                }
            }

            this.sorting = sorting;
        },
        getSortingCondition(field, sorting) {
            const organisationType = this.$store.getters.getOrganisationType;

            switch (field) {
                case 'date':
                    return `creationDate__` + sorting
                case 'statusLabel':
                    return `${organisationType}Status__${sorting}`
                default:
                    return `${field}__${sorting}`
            }
        },
        handleUpdateTable() {
            if(!this.initializedTableLength) return

            this.get();
            this.getProjectsMinimal();
        },
        handleFilterUpdated(filter) {
            this.setFilters(filter);
            this.handleUpdateTable();
        },
        handleFiltersInitialized(filter) {
            this.setFilters(filter);
            this.getProjectsMinimal();
        },
        _filterSearchChanged: _.debounce(function(data) {
            return this.filterSearchChanged(data);
        }, 500),
        filterSearchChanged({ column, filterData }) {
            switch (column) {
                case 'name':
                    this.getProjectFilterOptions(filterData);
                    break;
                case 'contractor':
                    this.getOrganisationFilterOptions(filterData.search);
                    break;
                case 'client':
                    this.getOrganisationFilterOptions(filterData.search);
                    break;
                case 'members':
                    this.getMembersFilterOptions(filterData.search);
                case 'statusLabel':
                    this.getStatusFilterOptions(filterData.search);
            }
        },
        getProjectFilterOptions(filterData = {}) {
            this.loadingProjectFilterOptions = true;
            const search = filterData.search || '';

            const selectedProjectIds = filterData.filter || [];

            this.$apollo
                .query({
                    query: GET_PROJECTS_MINIMAL,
                    variables: {
                        where: {
                            OR: [
                                { name__contains: search },
                                { id__in: selectedProjectIds }
                            ]
                        },
                        caseSensitive: false
                    },
                    fetchPolicy: 'network-only'
                })
                .then(result => {
                    this.loadingProjectFilterOptions = false;
                    this.projectsFilterOptions = result.data.projects.map(project => {
                        return {
                            value: project.id,
                            label: this.getProjectName(project),
                            hidden: selectedProjectIds.includes(project.id)
                        };
                    });
                })
                .catch(error => {
                    this.loadingProjectFilterOptions = false;
                    console.log(error);
                });
        },
        async getOrganisationFilterOptions(search) {
            this.loadingOrganisationFilterOptions = true;
            const organisationType = this.$store.getters.getOrganisationType;
            const slug = organisationType === 'client' ? 'contractor' : 'client';

            this.$apollo
                .query({
                    query: ORGANISATIONS_MINIMAL,
                    variables: {
                        where: {
                            AND: [
                                {
                                    name__contains: search
                                },
                                {
                                    type: 'main'
                                },
                                {
                                    id__in: this.projectsOrganisationIds
                                }
                            ]
                        },
                        first: 20,
                        sort: ['name__DESC'],
                        caseSensitive: false
                    },
                    fetchPolicy: 'cache-first'
                })
                .then(result => {
                    this.loadingOrganisationFilterOptions = false;
                    this.organisationFilterOptions = result.data.organisations.map(organisation => {
                        return {
                            value: organisation.id,
                            label: organisation.name
                        };
                    });
                })
                .catch(error => {
                    this.loadingOrganisationFilterOptions = false;
                });
        },
        getStatusFilterOptions(search = '') {
            this.loadingStatusFilterOptions = true;
            if(search === '') return this.setAllProjectStatusOptions()

            const statusses = this.allMinimalProjects.map(project => {
                return {
                    value: project.status,
                    label: project.statusLabel
                };
            });
            let mappedStatusses = statusses.map(status => status.value);
            let filteredDuplicates = statusses.filter((status, index) => mappedStatusses.indexOf(status.value) == index && status.label.toLowerCase().includes(search.toLowerCase()));
            this.statusFilterOptions = filteredDuplicates;
            this.loadingStatusFilterOptions = false;
        },
        getMembersFilterOptions(searchQuery) {
            this.loadingMemberFilterOptions = true;
            let { users } = this.$store.getters.getCurrentOrganisation;

            if (searchQuery) {
                users = users.filter(user => 
                    user.user.firstName.includes(searchQuery) || 
                    user.user.lastName.includes(searchQuery) || 
                    user.user.email.includes(searchQuery))
            }

            users = users.filter(user => user.user && (user.user.firstName || user.user.lastName));
            
            this.membersFilterOptions = users.map(user => {
                return {
                    value: user.userId,
                    label: `${user.user.firstName} ${user.user.lastName}`
                }
            })
            this.loadingMemberFilterOptions = false;
        },
        handleTablePagination(selectedPageIndex) {
            this.activeTableIndex = selectedPageIndex - 1;
            if(!this.initializedTableLength) return

            this.get();
        },
        getProjectsMinimal(initialize = false) {
            this.$apollo.query({
                query: GET_PROJECTS_MINIMAL,
                variables: {
                    where: initialize ? {} : this.whereQuery
                },
                fetchPolicy: 'no-cache'
            })
            .then(result => {
                if(!this.hasProjects) this.hasProjects = result.data.projects.length > 0;

                const organisationType = this.$store.getters.getOrganisationType;
                const projectsOrganisationIds = [];
                const oppositeType = organisationType === 'contractor' ? 'client' : 'contractor'
                result.data.projects.forEach(project => {
                    const id = project[oppositeType+'Id'];
                    if(!id || projectsOrganisationIds.includes(id)) return
                    projectsOrganisationIds.push(id)
                });
                this.projectsOrganisationIds = projectsOrganisationIds;

                const projects = result.data.projects.map(project => {
                    const status = project[`${organisationType}Status`] || project.status;
                    project.status = status;
                    project.statusLabel = this.$t(`projectStatus.${status}`);
                    return project
                });
                
                if(initialize) {
                    this.allMinimalProjects = projects;
                    if(this.projectsMinimal.length === 0) this.projectsMinimal = projects;

                    this.getOrganisationFilterOptions();
                    this.setAllProjectStatusOptions();
                    this.getStatusFilterOptions();
                    this.getMembersFilterOptions();
                }
                else this.projectsMinimal = projects;
            })
            .catch(error => console.log(error))
        },
        getMembersAvatars(members) {
            return members.map(member => {
                return {
                    image: member.avatar,
                    fallback: userInitials(member.firstName, member.lastName),
                    tooltip: `${member.firstName} ${member.lastName}`
                }
            });
        },
        getProjectName(project) {
            const organisationType = this.$store.getters.getOrganisationType;
            const key = `${organisationType}ProjectName`;
            const projectName = project[key];
            return projectName || project.name
        },
        setAllProjectFilterOptions() {
            this.projectsFilterOptions = this.allMinimalProjects.map(project => {
                return {
                    label: this.getProjectName(project),
                    value: project.id
                }
            })
        },
        setAllProjectStatusOptions() {
            const statusses = this.allMinimalProjects.map(project => {
                return {
                    value: project.status,
                    label: project.statusLabel
                };
            });
            let mappedStatusses = statusses.map(status => status.value);
            let filteredDuplicates = statusses.filter((status, index) => mappedStatusses.indexOf(status.value) == index);
            this.statusFilterOptions = filteredDuplicates;
            this.loadingStatusFilterOptions = false;
        },
        initializeFilterData() {
            this.getProjectsMinimal(true);
            this.getProjectFilterOptions();
        },
        getFetchPolicy() {
            const routerEvent = this.$store.getters.getLastRoutingEvent;
            if(Object.keys(routerEvent).length === 0) return 'no-cache'

            const from = this.removeQueryParams(routerEvent.from);
            const to = this.removeQueryParams(routerEvent.to);
            return from === to ? 'cache' : 'no-cache'
        },
        removeQueryParams(url) {
            if(!url.includes('?')) return url
            return url.split('?')[0]
        },
        transformColumns(projects) {
            return projects.map(project => {
                const contractor = project.contractor ? project.contractor.name : '';
                const client = project.client ? project.client.name : '';
                let orgUsers = this.$store.getters.getCurrentOrganisation.users


                delete project.defaultContext;

                project.members = project.members.map(member => {
                    let user = orgUsers.find(orgUser => orgUser.userId === member.userId)
                    if (user && user.user) return { ...member, ...user.user }
                })

                project.members = project.members.filter(member => member)
                project.memberString = project.members.map(member => `${member.firstName} ${member.lastName}`).join(', ');
                const organisationType = this.$store.getters.getOrganisationType;
                const status = project[`${organisationType}Status`] || project.status;
                return {
                    ...project,
                    contractor,
                    client,
                    to: project.status === 'finished' && !this.canViewFinishedProjects ? '' : `/projects/${project.id}?title=${project.name}`,
                    name: this.getProjectName(project),
                    dateFormatted: this.getDate(project.creationDate),
                    statusLabel: this.$t(`projectStatus.${status}`)
                }
            }) 
        },
        async getProjectBatch(first, skip) {
            for (let i = 0; i <= 3; i++) {
                await new Promise(r => setTimeout(r, 400*i*i))
                try {
                    const projectBatch = await this.$apollo.query({
                        query: GET_PROJECTS,
                        variables: {
                            where: this.whereQuery,
                            sort: this.sorting,
                            first,
                            skip
                        },
                        fetchPolicy: 'no-cache'
                    });
                    return Array.isArray(projectBatch.data.projects) ? projectBatch.data.projects : null;
                } catch {}
            }
            return null;                
        },
        async downloadExport() {
            this.exportingProjects = true;
            this.exportedProjects = [];
            const first = 10;
            const projectCount = this.projectsMinimal.length;
            for (let skip = 0; skip < projectCount; skip += first) {                
                const projectBatch = await this.getProjectBatch(first, skip);
                if (projectBatch === null) {
                    this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het ophalen van de projecten' })
                    this.exportingProjects = false;
                    return;
                }
                this.exportedProjects = [ ...this.exportedProjects, ...this.transformColumns(projectBatch)];                
            }
            const columns = this.columns.filter(column => column.field !== 'norm').map(column => { 
                if (column.field === 'date') return { ...column, field: 'dateFormatted' } ;
                if (column.field === 'members') return { ...column, field: 'memberString' };
                return column;
            });
            const fileName = this.selectedFiltersLength ? 'Projecten (gefilterd).csv' : 'Projecten.csv';
            handleExportCsv(this.exportedProjects, columns, fileName);
            this.exportingProjects = false;
        }
    },
    computed: {
        canCreateProject: function() {
            return this.organisationType === 'client' && !this.hybridOrganisation() || true
        },
        organisationType: function() {
            return this.$store.getters.getOrganisationType;
        },
        columns: function() {
            const organisationType = this.$store.getters.getOrganisationType;

            if (organisationType === 'contractor')
                return [
                    {
                        field: 'norm',
                        label: 'Norm',
                        width: '50px',
                        loadingWidth: '60%'
                    },
                    {
                        field: 'name',
                        label: 'Projectnaam',
                        width: '200px',
                        filter: true,
                        filterType: 'checkbox',
                        filterOptions: this.projectsFilterOptions,
                        loading: this.loadingProjectFilterOptions
                    },
                    {
                        field: 'client',
                        label: 'Opdrachtgever',
                        width: '150px',
                        filter: true,
                        filterType: 'checkbox',
                        filterOptions: this.organisationFilterOptions,
                        disableSorting: true,
                        loading: this.loadingOrganisationFilterOptions
                    },
                    {
                        field: 'date',
                        label: 'Datum',
                        width: '70px',
                        filter: true,
                        filterType: 'datePicker',
                        defaultSort: 'DESC'
                    },
                    {
                        field: 'statusLabel',
                        label: 'Status',
                        align: 'right',
                        width: '140px',
                        loadingStyle: 'badge',
                        filter: true,
                        filterType: 'checkbox',
                        filterOptions: this.statusFilterOptions,
                        loading: this.loadingStatusFilterOptions
                    },
                    
                ];
            else if (organisationType === 'client')
                return [
                    {
                        field: 'norm',
                        label: 'Norm',
                        width: '50px',
                        loadingWidth: '60%'
                    },
                    {
                        field: 'name',
                        label: 'Projectnaam',
                        width: '200px',
                        filter: true,
                        filterType: 'checkbox',
                        filterOptions: this.projectsFilterOptions
                    },
                    {
                        field: 'contractor',
                        label: 'Opdrachtnemer',
                        width: '150px',
                        filter: true,
                        filterType: 'checkbox',
                        filterOptions: this.organisationFilterOptions,
                        disableSorting: true,
                        loading: this.loadingOrganisationFilterOptions
                    },
                    {
                        field: 'date',
                        label: 'Datum',
                        width: '70px',
                        filter: true,
                        filterType: 'datePicker'
                    },
                    {
                        field: 'members',
                        label: 'Projectleden',
                        width: '95px',
                        filter: true,
                        filterType: 'checkbox',
                        filterOptions: this.membersFilterOptions,
                        disableSorting: true,
                        tooltip: true,
                        loading: this.loadingMemberFilterOptions
                    },
                    {
                        field: 'statusLabel',
                        label: 'Status',
                        width: '140px',
                        align: 'right',
                        loadingStyle: 'badge',
                        filter: true,
                        filterType: 'checkbox',
                        filterOptions: this.statusFilterOptions,
                        loading: this.loadingStatusFilterOptions
                    }
                ];
            else return [];
        },
        canViewFinishedProjects: function() {
            const { products } = this.$store.getters.getCurrentOrganisation;
            const organisationType = this.$store.getters.getOrganisationType;

            if (organisationType !== 'contractor') return true;

            const slugs = products.map(product => product.slug);
            const allowedProducts = ['crow_contractor', 'pd_basis', 'pd_full'];

            return allowedProducts.some(product => slugs.includes(product));
        },
        formattedProjects: function() {
            return this.paginatedProjects.map(project => {
                if (this.canViewFinishedProjects) return project;
                return {
                    ...project,
                    __customStyling: project.status === 'finished' ? 'background-color: #d6d6d6' : ''
                };
            });
        },
        canCreateNewProject: function() {
            return canCreateNewProject()
        },
        freemiumOrganisation: function() {
            return freemiumOrganisation()
        },
        exportButtonText: function() {
            if (this.exportingProjects) {
                const exportingPercentage = Math.round(100 * this.exportedProjects.length / this.projectsMinimal.length)
                return `${exportingPercentage}%`;
            } else {
                return 'Exporteren';
            }
        },
    },
    watch: {
        initializedTableLength: function() {
            if(this.initializedTableLength) this.handleUpdateTable();
        }
    },
    created() {
        this.initializeFilterData();
    },
    mounted() {
        this.paginatedTableLength = getCalculatedRows(10);
        this.initializedTableLength = true;
    }
};
</script>

<style scoped lang="scss">
.page-padding {
    padding-bottom: 0;
}

.zerostate {
    margin-top: 100px;
}

.boldText {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}

h2 {
    font-weight: 500;
    font-size: 31px;
    line-height: 33px;
}

.crow-logo {
    width: 50px;
}

.icon .button_icon {
    padding-top: 0px !important;
}
progress[value] {
  --w: 100%; /* the width*/  
  --color:  /* the progress color */
    linear-gradient(90deg,#00A1AE,#006168) 0/var(--w);
  --background: lightgrey; /* the background color */

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  width: var(--w);
  height: 30px;
  margin: 10px;
  border-radius: 0.7em;
  background: var(--background);
}
progress[value]::-webkit-progress-bar {
  border-radius: 0.7em;
  background: var(--background);
}
progress[value]::-webkit-progress-value {
  border-radius: 0.7em;
  background: var(--color);
}
progress[value]::-moz-progress-bar {
  border-radius: 0.7em;
  background: var(--color);
}

</style>
