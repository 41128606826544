<template>
    <div style="display: flex; align-items: center">
        <div v-if="edit" class="edit-wrapper">
            <div v-if="value.singleValue" class="dropdown-wrapper">
                <q-dropdown
                    size="small"
                    v-model="item.filters.value"
                    :placeholder="`Kies beoordelingswaarden`"
                    :options="getAnswerValues"
                    autocomplete
                    autofocus
                    @input="apply"
                ></q-dropdown>
            </div>
            
            <div v-else-if="['dropdown','organisation','dropdown-multiple','radio','checkbox'].includes(value.answerType)" class="dropdown-wrapper">
                <q-dropdown
                    size="small"
                    v-model="item.filters.values"
                    :placeholder="`Kies beoordelingswaarden`"
                    :options="getAnswerValues"
                    multiple
                    autocomplete
                    autofocus
                ></q-dropdown>
            </div>

            <div v-else-if="['number','score'].includes(item.answerType)" :key="item.filters.type" class="filter-controls">
                <div class="controlls">
                    <button
                        class="custom-button"
                        @click="setFilterType('lt')"
                        :class="item.filters.type === 'lt' ? 'active' : ''"
                    >
                        &lt;
                    </button>
                    <button
                        class="custom-button"
                        @click="setFilterType('eq')"
                        :class="item.filters.type === 'eq' ? 'active' : ''"
                    >
                        =
                    </button>
                    <button
                        class="custom-button"
                        @click="setFilterType('gt')"
                        :class="item.filters.type === 'gt' ? 'active' : ''"
                    >
                        &gt;
                    </button>
                </div>

                <q-input :inputType="item.displayType === 'currency' ? 'currency' : 'number'" :unit="item.unit" size="small" v-model="item.filters.value" class="input" autofocus></q-input>
            </div>

            <div v-else-if="item.answerType === 'date'" :class="[reportPageFilterBar === true ? '' : 'date-picker']">
                <el-date-picker
                    :default-value="new Date()"
                    value-format="timestamp"
                    format="dd-MM-yyyy"
                    placeholder="Vanaf"
                    v-model="item.filters.values[0]"
                ></el-date-picker>
                <el-date-picker
                    :default-value="new Date()"
                    value-format="timestamp"
                    format="dd-MM-yyyy"
                    placeholder="Tot en met"
                    v-model="item.filters.values[1]"
                ></el-date-picker>
            </div>

            <div v-else-if="item.answerType === 'date-range-to'" :class="[reportPageFilterBar === true ? '' : 'date-picker']">
                <el-date-picker
                    :default-value="new Date()"
                    value-format="timestamp"
                    format="dd-MM-yyyy"
                    placeholder="Kies een datum"
                    v-model="item.filters.values[0]"
                ></el-date-picker>
            </div>

            <div v-else-if="item.answerType === 'text'" class="">
                <q-text size="small" v-model="item.filters.value" :placeholder="`Kies trefwoorden`"></q-text>
            </div>

            <div class="filter-check-close">
                <button class="custom-button active" @click="apply">
                    <q-icon type="Check" color="white" width="14" height="14"></q-icon>
                </button>
                <button class="custom-button white" @click="cancel">
                    <q-icon type="Cancel" color="#212529" width="14" height="14"></q-icon>
                </button>
            </div>
        </div>

        <div v-else class="filter-wrapper">
            <div class="left">
                <div v-if="['dropdown','organisation','radio','dropdown-multiple','checkbox'].includes(value.answerType) && item.filters.values.length > 0 && !value.singleValue" class="options-wrapper">
                    <q-tag v-for="option in statusOptions" :key="option.value" variation="default" size="small" class="option">
                        <p>{{ option.label }}</p>
                        <q-icon type="close" class="remove-icon" @click.stop="handleRemoveValue(option)"></q-icon>
                    </q-tag>
                </div>
                <span v-else-if="statusText" class="choose-label" :class="{ 'has-chosen': item.filters.value }" @click="edit = true">{{ statusText }}</span>
            </div>
            <div v-if="item.filters.values.length > 0" class="right">
                <q-button size="xsmall" variation="ghost" @click="edit = true">Aanpassen</q-button>
            </div>
        </div>
    </div>
</template>

<script>
import { getMoney } from '@/assets/js/utils';
import { getDate } from '../../../../assets/js/utils';

export default {
    name: 'checkbox-bar',
    props: {
        value: {
            type: Object,
            default() {},
        },
        reportPageFilterBar: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            edit: false,
            activeButton: null,
            numberValue: 0,
            selected: [],
            statusText: '',
            statusOptions: [],
            item: {
                filters: {
                    exists: false,
                    value: '',
                    values: [],
                },
            },
        };
    },
    methods: {
        setFilterType(filterType) {
            this.item.filters.type = filterType;
        },
        handleControllButtons(index) {
            this.activeButton = this.activeButton === index ? null : index;
        },
        apply() {
            if (this.item.answerType === 'date-range-to') {
                this.item.filters.exists = true;
                this.item.filters.value = this.item.filters.values;
                this.statusText = this.getAppliedFilters();
                this.statusOptions = this.getAppliedOptions();
                this.edit = false;
                this.item.checked = true;
                this.$emit('input', this.item);
            } else if (this.item.answerType === 'date') {
                this.item.filters.exists = true; 
                this.item.filters.value = this.item.filters.values;
                this.statusText = this.getAppliedFilters();
                this.statusOptions = this.getAppliedOptions();
                this.edit = false;
                this.item.checked = true;
                this.$emit('input', this.item);
            } else {
                this.item.filters.exists = true;

                this.statusText = this.getAppliedFilters();
                this.statusOptions = this.getAppliedOptions();
                this.edit = false;
                this.item.checked = true;
                this.$emit('input', this.item);
            }
        },
        cancel() {
            this.item.filters.exists = false;
            this.item.filters.value = undefined;
            this.item.filters.values = [];
            this.statusText = this.getAppliedFilters();
            this.statusOptions = this.getAppliedOptions();
            this.edit = false;
            this.item.checked = false;
            this.$emit('input', this.item);
        },
        getAppliedOptions() {
            if(!['dropdown','organisation','radio','dropdown-multiple','checkbox'].includes(this.item.answerType)) return []
            const appliedOptions = this.item.filters.values;
            let options = [];
            appliedOptions.forEach(option => {
                const label = this.getAnswerLabel(this.item.answerValues, option);
                const value = option;
                options.push({ label, value });
            });
            return options
        },
        getAppliedFilters() {
            let string = '';
            let answerType = this.item.answerType;
            let displayType = this.item.displayType;
            if (this.item.singleValue) {
                const value = this.item.filters.value;
                if(value) string = this.getAnswerLabel(this.item.answerValues, value);
                else string = `Kies beoordelingswaarden`;
            } else if (answerType === 'text') {
                const value = this.item.filters.value;
                if(value) string = `Zoeken op: '${value}'`;
                else string = `Kies trefwoorden`;
            } else if (answerType === 'organisation') {
                let values = this.item.filters.value;

                if(this.item.loading) return string = 'laden...'

                if (values && values.length) {
                    if (values.length === 1) {
                        string = this.getAnswerLabel(this.item.answerValues, values[0]);
                    } else {
                        values.forEach((value, index) => {
                            if (index + 2 === values.length)
                                string += `${this.getAnswerLabel(this.item.answerValues, value)}`;
                            else if (index + 1 < values.length)
                                string += `${this.getAnswerLabel(this.item.answerValues, value)}, `;
                            else string += ` en ${this.getAnswerLabel(this.item.answerValues, value)}`;
                        });
                    }
                } else {
                    string = `Kies ${this.item.markedName}`;
                }
            } else if (['dropdown','radio','dropdown-multiple','checkbox'].includes(answerType)) {
                let values = this.item.filters.values;

                if (values && values.length) {
                    if (values.length === 1 || values.length === 1 && values[0].length === 1) {
                        string = this.getAnswerLabel(this.item.answerValues, values[0]);             
                    } else {
                        values.forEach((value, index) => {
                            if (index + 2 === values.length)
                                string += `${this.getAnswerLabel(this.item.answerValues, value)}`;
                            else if (index + 1 < values.length)
                                string += `${this.getAnswerLabel(this.item.answerValues, value)}, `;
                            else string += ` en ${this.getAnswerLabel(this.item.answerValues, value)}`;
                        });
                    }
                } else {
                    string = `Kies beoordelingswaarden`;
                }
            } else if (answerType === 'number' && displayType === 'currency') {
                let filters = this.item.filters;

                if (filters.value === undefined || filters.value === null) string = `Kies bedrag`;
                else if (filters.type === 'gt') string = `${getMoney(filters.value)} of hoger`;
                else if (filters.type === 'lt') string = `${getMoney(filters.value)} of lager`;
                else if (filters.type === 'eq') string = getMoney(filters.value);
            } else if (answerType === 'number') {
                let filters = this.item.filters; 
                const unit = this.item.unit; 
      
                if (filters.value === undefined || filters.value === null) {
                    if (displayType === 'number') string = `Kies getal`;
                    if (displayType === 'numberUnit') string = `Kies getal`;
                 }
                
                else if (filters.type === 'gt') string = `hoger dan een ${(filters.value)} ${unit}`;
                else if (filters.type === 'lt') string = `lager dan een ${(filters.value)} ${unit}`;
                else if (filters.type === 'eq') string = `${filters.value} ${unit}`;
            } else if (answerType === 'date') {
                if (this.item.filters) {
                    if (
                        (!this.item.filters && !this.item.filters.values) ||
                        (this.item.filters && !this.item.filters.values[0] && !this.item.filters.values[1])
                    )
                        return `Kies datum`;

                    if (this.item.filters && this.item.filters.values[0])
                        string += 'van ' + getDate(this.item.filters.values[0]);
                    if (this.item.filters && this.item.filters.values[1])
                        string += ' tot ' + getDate(this.item.filters.values[1]);
                } else {
                    return;
                }
            } else if (answerType === 'date-range-to') {
                if (this.item.filters) {
                    if (
                        (!this.item.filters && !this.item.filters.values) ||
                        (this.item.filters && !this.item.filters.values[0])
                    )
                        return `Kies datum`;
                        
                    if (this.item.filters && this.item.filters.values[0])
                        string += getDate(this.item.filters.values[0]);

                } else {
                    return;
                }
            } else if (answerType === 'boolean') {
                return
            } else {
                string = '';
                console.warn(`unknown answerType: ${answerType}`);
            }
            return string;
        },
        getAnswerLabel(options = [], item) {
            if(!options) return 'laden...'
            let option = [];
            if (typeof item === 'object') {
                option = options.filter((option) => option.value === item.value || option.value === item[0]);
            } else if (typeof item === 'string') {
                option = options.filter((option) => option.value === item);
            } else if (typeof item === 'number') {
                option = options.filter((option) => option.value === item);
            } else if (typeof item === 'boolean') {
                option = options.filter((option) => option.value === item);
            }
            return option[0].label;
        },
        parseItem(item) {
            if (item.filters && !item.filters.values) item.filters.values = [];
            if (['dropdown','organisation','dropdown-multiple','radio','checkbox'].includes(item.answerType)) item.filters.type = 'INCLUDES-OR';
            return item;
        },
        handleRemoveValue(option) {
            const { values } = this.item.filters;
            const newValues = values.filter(value => value !== option.value);
            this.item.filters.values = [ ...newValues ];
            this.apply();
        }
    },
    watch: {
        value: function () {
            this.item = this.parseItem({ ...this.value });
            this.statusText = this.getAppliedFilters();
            this.statusOptions = this.getAppliedOptions();
        },
    },
    computed: {
        getAnswerValues: function () {
            return this.item.answerValues;
        },
    },
    mounted() {
        this.statusText = this.getAppliedFilters();
        this.statusOptions = this.getAppliedOptions();
    },
    created() {
        this.item = this.parseItem(this.value);
    }
};
</script>

<style lang="scss" scoped>
@import '../../../../components/qds/assets/style/_variables.scss';
@import '../../../../components/qds/assets/style/fonts/fonts.css';

.custom-button {
    cursor: pointer;
    transition: 0.2s ease;

    &:hover {
        scale: 1.04;
    }

    &:focus,
    &:active {
        scale: 0.96;
    }
}

.bar-wrapper {
    .filter-wrapper {
        display: flex;
        justify-content: space-between;
        width: -webkit-fill-available;

        .left {
            flex-grow: 1;

            .options-wrapper {
                display: flex;
                flex-wrap: wrap;
                gap: 6px;
                padding-block: 6px;
    
                .option {
                    position: relative;
                    overflow: hidden;
                    cursor: default;
    
                    &:hover {
                        .remove-icon {
                            right: -4px;
                        }
                    }

                    p {
                        font-size: 12px;
                        font-weight: 500;
                        white-space: nowrap;
                        max-width: 20vw;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
    
                    .remove-icon {
                        position: absolute;
                        right: -24px;
                        top: 55%;
                        translate: 0 -50%;
                        scale: 0.7;
                        background-color: $color-grey-3;
                        padding: 6px;
                        border-radius: 50%;
                        transition: right .2s ease;
                        cursor: pointer;
    
                        &:hover {
                            color: red;
                        }
                    }
                }
            }
        }

        .right {
            margin-block: auto;
        }

        .custom-button {
            font-weight: $weight-semi-bold;
            font-size: $size-m;
            font-family: $font-text;
            line-height: $line-height-m;
            text-decoration: none;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: $radius-default;
            color: $color-primary;

            background: $color-grey-5;
            color: $color-white;
            border: 1px solid rgb(150, 150, 150);
            box-shadow: none;

            padding: 5px;
            width: 30px;
            height: 30px;

            transition: 0.2s ease;

            &.active {
                border: $color-primary;
                background: $color-primary;
            }

            &.white {
                background: #ffffff;
                border: #ffffff;
                color: #000000;
            }
        }

        .filter-check-close {
            display: flex;
            gap: 6px;
            margin-left: 8px;
        }

        .edit-wrapper {
            width: 100%;
            display: flex;
            align-items: center;

            .dropdown-wrapper {
                width: 100%;
                position: relative;
            }
            .filter-controls {
                width: 100%;
                display: flex;
                align-items: center;
                gap: 4px;
                padding: 3px;
                border: 1px solid #dadada;
                border-radius: 5px;
                background: white;

                .input {
                    width: 100%;
                    padding: 0;
                }

                .controlls {
                    display: flex;

                    .custom-button {
                        height: 26px;
                        width: 26px;
                        margin: 0px 3px;
                    }
                }
            }
        }
    }

    .result-count {
        width: 200px;
        display: flex;
        justify-content: flex-end;
    }
}

.choose-label {
    display: block;
    width: 100%;
    color: $color-grey-5;
    font-size: 14px;
    line-height: 20px;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
    padding: 5.5px 8px;
    border-radius: 4px;
    cursor: pointer;
    
    &.has-chosen {
        color: $color-grey-9;
        font-weight: 500;
    }

    &:not(.has-chosen):hover {
        background-color: #ebeeee;
    }
}
</style>
